import * as React from "react"
import Hero from "../../../components/hero"
import { File } from "../../../graphql-types"

interface HomeHeroProps {
  heroImage: File
}

export default function HomeHero({ heroImage }: HomeHeroProps) {
  return (
    <Hero
      title={"Find Secondhand and Vintage Stores in Japan!"}
      className={"max-w-auto"}
      titleClassName={"text-white text-center text-3xl md:text-6xl font-bold"}
      heroImage={heroImage}
      hideOverlay
    />
  )
}
