import * as React from "react"
import { Location } from "../../../graphql-types"
import { Link } from "gatsby"
import * as urlHelpers from "../../../lib/url-helpers"
import { getImage, ImageDataLike } from "gatsby-plugin-image"

interface LocationBannerProps {
  location: Location
}

export default function LocationBanner({ location }: LocationBannerProps) {
  const banner = getImage(location.bannerImage as unknown as ImageDataLike)
  return (
    <div className={"w-full md:w-1/3 p-2"}>
      <Link
        to={urlHelpers.locationUrl(location)}
        className={
          "block bg-cover relative h-36 hover:opacity-90 transition-opacity"
        }
        style={{
          backgroundImage: `url(${banner?.images.fallback?.src})`,
        }}
      >
        <span
          className={
            "absolute bottom-0 px-4 py-2 bg-black text-white uppercase text-lg"
          }
        >
          {location.title}
        </span>
      </Link>
    </div>
  )
}
