import * as React from "react"
import Container from "../../../components/container"
import StoreTile from "../../store/store-tile"
import ButtonLink from "../../../components/button-link"
import * as urlHelpers from "../../../lib/url-helpers"
import usePopularStores from "../../../hooks/use-popular-stores"
import Heading from "../../../components/heading"

export default function HomePopular() {
  const popularStores = usePopularStores()

  return (
    <Container
      fullWidth
      className="px-6 py-12 items-center flex-col bg-gray-50"
    >
      <Heading size={"large"}>Popular</Heading>
      <Container breakpoint="md" className="py-12 items-start flex-wrap">
        {popularStores.map(store => (
          <div className={"sm:w-1/2 lg:w-1/4 px-2 py-2 md:py-0"} key={store.id}>
            <StoreTile store={store} />
          </div>
        ))}
      </Container>
      <ButtonLink
        theme={"dark-outline"}
        hoverTheme={"dark"}
        href={urlHelpers.storesUrl()}
      >
        View More
      </ButtonLink>
    </Container>
  )
}
