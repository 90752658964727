import * as React from "react"
import Container from "../../../components/container"
import LocationBanner from "../../location/location-banner"
import usePopularLocations from "../../../hooks/use-popular-locations"

export default function HomeLocations() {
  const locations = usePopularLocations()

  return (
    <Container breakpoint="lg" className={"px-6 py-12 items-start flex-wrap"}>
      {locations.map(location => (
        <LocationBanner key={location.id} location={location} />
      ))}
    </Container>
  )
}
