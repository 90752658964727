import { graphql, useStaticQuery } from "gatsby"
import { PopularStoresQuery, Store } from "../graphql-types"

export default function usePopularStores() {
  const { allStore } = useStaticQuery<PopularStoresQuery>(
    graphql`
      query PopularStores {
        allStore(
          filter: { popular: { eq: true } }
          sort: { fields: title, order: ASC }
        ) {
          nodes {
            popular
            id
            instagramUrl
            latitude
            longitude
            phoneNumber
            placeId
            pricing
            slug
            title
            tradingHours
            twitterUrl
            body
            address
            appleMapsAddress
            description
            facebookUrl
            googleMapsAddress
            googleRating
            heroImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            featureImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            varieties {
              title
              slug
            }
            location {
              title
              slug
            }
            chain {
              title
              slug
              postImage {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    `
  )

  return allStore.nodes as Store[]
}
