import * as React from "react"
import Layout from "../components/layout"
import HomeHero from "../partials/home/home-hero"
import HomeLocations from "../partials/home/home-locations"
import HomePopular from "../partials/home/home-popular"
import { graphql, PageProps } from "gatsby"
import { File, HomePageQuery } from "../graphql-types"

export default function IndexPage({ data }: PageProps<HomePageQuery>) {
  return (
    <Layout noGap>
      <HomeHero heroImage={data.heroImage as File} />
      <HomeLocations />
      <HomePopular />
    </Layout>
  )
}

export const query = graphql`
  query HomePage {
    heroImage: file(relativePath: { eq: "opshop.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
