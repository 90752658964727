import { useStaticQuery, graphql } from "gatsby"
import { Location, PopularLocationsQuery } from "../graphql-types"

export default function usePopularLocations() {
  const { allLocation } = useStaticQuery<PopularLocationsQuery>(
    graphql`
      query PopularLocations {
        allLocation(
          filter: { popular: { eq: true } }
          sort: { fields: title, order: ASC }
        ) {
          nodes {
            id
            title
            slug
            bannerImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    `
  )

  return allLocation.nodes as Location[]
}
